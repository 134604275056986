import React from 'react'
import cx from 'classnames'
import { format } from 'date-fns'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

import { trimString } from '../../config/helpers'

import Link from '../Link'

import './style.scss'

const PostCard = ({
    meta,
    heroImage,
    title,
    slug,
    excerpt,
    game,
    backgroundColour = 'White',
    className,
}) => {
    const createdAt = format(new Date(meta?.createdAt), 'MMMM dd / yyyy')

    return (
        <div { ...{ className: cx(className, 'relative transform-gpu hover:scale-105 hover:z-20 group origin-center transition duration-200') } }>
            <div className="w-11/12 h-5/6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-4xl pointer-events-none transition opacity-0 group-hover:opacity-100 duration-200" />
            <Link to={`/news/${slug}`} className="post-card flex flex-col min-h-full overflow-hidden relative group-hover:z-40">
                {heroImage?.mobile && <Img fluid={heroImage.mobile} className="post-card__image w-full align-top" />}
                <div className={`post-card__inner flex-1 p-6 ${backgroundColour === 'Grey' ? 'bg-bare' : 'bg-white'}`}>
                    <span className={`font-bold ${game?.primaryColour?.hex ? 'text-blue-dark' : 'text-secondary'} group-hover:text-boulder`}>{createdAt}</span>
                    <h3 className="my-4 text-2xl text-black font-bold">{title}</h3>
                    {excerpt && <div className="wysiwyg"><ReactMarkdown children={trimString({ str: excerpt })} /></div>}
                </div>
                <div className={`post-card__link p-6 relative border-t border-dashed group-hover:z-40 ${backgroundColour === 'Grey' ? 'border-aldo' : 'border-gallery' } overflow-hidden`}>
                    <span className="relative text-primary text-lg font-bold uppercase group-hover:text-secondary z-10" style={{ color: game?.primaryColour?.hex }}>Read full article</span>
                </div>
            </Link>
        </div>
    )
}

export default PostCard
